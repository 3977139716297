$backgroundColor: #ededed;
$primaryColor: #232f3e;
$secondaryColor: #008296;

@mixin flex($justify: center, $align: center, $dir: row) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justify;
  align-items: $align;
}

@mixin dimensions($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}

@mixin nudge($amt: 0, $pos: top) {
  position: relative;
  #{$pos}: #{$amt}px;
}
